<template>
  <a-row :gutter="16">
    <a-col :sm="24" :lg="12" :xl="8" :xxl="6" style="margin-bottom: 16px;">
      <a-page-header :ghost="false">
        <template slot="extra">
          <a @click="$refs.updateForm.show(detail)" style="color: #faad14;">
            <a-space>
              <a-icon type="edit"></a-icon>
              <span>编辑</span>
            </a-space>
          </a>
          <update-form ref="updateForm" @ok="fetch"></update-form>
        </template>

        <div style="margin: 24px 0; text-align: center;">
          <a-avatar icon="user" :size="128" style="margin-bottom: 16px;"></a-avatar>
          <div style="font-size: 32px; font-weight: bold;">{{ detail.nickname }}</div>
          <div style="font-size: 16px; color: rgba(0, 0, 0, 0.25);">{{ detail.username }}</div>
        </div>
        <a-descriptions :column="1">
          <a-descriptions-item>
            <a-space slot="label">
              <a-icon type="phone"></a-icon>
              <span>手机号码</span>
            </a-space>
            <div>{{ detail.phone_number }}</div>
          </a-descriptions-item>
          <a-descriptions-item>
            <a-space slot="label">
              <a-icon type="mail"></a-icon>
              <span>邮箱地址</span>
            </a-space>
            <div>{{ detail.email_address }}</div>
          </a-descriptions-item>
          <a-descriptions-item>
            <a-space slot="label">
              <a-icon type="solution"></a-icon>
              <span>角色</span>
            </a-space>
            <a-tag color="blue">{{ roleName }}</a-tag>
          </a-descriptions-item>
          <a-descriptions-item>
            <a-space slot="label">
              <a-icon type="hourglass" />
              <span>注册时间</span>
            </a-space>
            <div>{{ detail.created_at }}</div>
          </a-descriptions-item>
        </a-descriptions>
      </a-page-header>
    </a-col>

    <a-col :sm="24" :lg="12" :xl="16" :xxl="18">
      <a-card
        :active-tab-key="activeTabKey"
        :body-style="{ height: '746px' }"
        :bordered="false"
        :tab-list="tabList"
        @tabChange="key => { this.activeTabKey = key }"
      >
        <project-tab v-show="activeTabKey === 'project'"></project-tab>
        <password-tab v-show="activeTabKey === 'password'"></password-tab>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { getUserProfile } from '@/api/user'
import PasswordTab from './modules/PasswordTab'
import ProjectTab from './modules/ProjectTab'
import UpdateForm from './modules/UpdateForm'

export default {
  name: 'UserProfile',
  components: {
    PasswordTab,
    ProjectTab,
    UpdateForm
  },
  data () {
    return {
      detail: {
        role: {}
      },
      activeTabKey: 'project',
      tabList: [
        { key: 'project', tab: '授权项目' },
        { key: 'password', tab: '密码修改' }
      ]
    }
  },
  computed: {
    roleName () {
      if (this.detail.admin) return '管理员'
      else {
        if (this.detail.role) return this.detail.role.named
        else return ''
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      getUserProfile().then(res => {
        this.detail = res.data
      })
    }
  }
}
</script>
