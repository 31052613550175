<template>
  <a-row>
    <a-col :sm="24" :xl="20" :xxl="12">
      <a-form-model
        ref="form"
        :label-col="{ span: 4, style: { textAlign: 'left' } }"
        :model="form"
        :rules="rules"
        :wrapper-col="{ span: 20 }"
      >
        <a-form-model-item label="原密码" prop="passwordOld">
          <a-input-password v-model="form.passwordOld" autocomplete="new-password" :max-length="128" placeholder="请输入原密码"></a-input-password>
        </a-form-model-item>
        <a-form-model-item label="新密码" prop="passwordNew">
          <a-input-password v-model="form.passwordNew" autocomplete="new-password" :max-length="128" placeholder="请输入新密码"></a-input-password>
        </a-form-model-item>
        <a-form-model-item label="确认密码" prop="passwordConfirm">
          <a-input-password v-model="form.passwordConfirm" autocomplete="new-password" :max-length="128" placeholder="请输入确认密码"></a-input-password>
        </a-form-model-item>
        <a-form-model-item>
          <a-space size="large">
            <a-button :loading="loading" type="primary" @click="ok">确定</a-button>
            <a-button @click="$refs.form.resetFields()">重置</a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>
    </a-col>
  </a-row>
</template>

<script>
import { updatePassword } from '@/api/user'

export default {
  name: 'PasswordTab',
  data () {
    return {
      form: {
        passwordOld: '',
        passwordNew: '',
        passwordConfirm: ''
      },
      rules: {
        passwordOld: [
          { message: '请输入原密码', required: true, trigger: 'blur' },
          { message: '请输入至少 6 位密码', min: 6, trigger: 'blur' }
        ],
        passwordNew: [
          { message: '请输入新密码', required: true, trigger: 'blur' },
          { message: '请输入至少 6 位密码', min: 6, trigger: 'blur' },
          {
            validator: (_, value, callback) => {
              if (value === this.form.passwordOld) {
                callback(new Error('新密码与原密码相同'))
              }
              callback()
            },
            trigger: 'blur'
          }
        ],
        passwordConfirm: [
          { message: '请输入确认密码', required: true, trigger: 'blur' },
          { message: '请输入至少 6 位密码', min: 6, trigger: 'blur' },
          {
            validator: (_, value, callback) => {
              if (value !== this.form.passwordNew) {
                callback(new Error('两次输入的密码不一致'))
              }
              callback()
            },
            trigger: 'blur'
          }
        ]
      },
      loading: false
    }
  },
  methods: {
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const data = {
            password_old: this.form.passwordOld,
            password_new: this.form.passwordNew
          }
          updatePassword(data).then(res => {
            this.$message.success(res.message)
            this.$refs.form.resetFields()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
