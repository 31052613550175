<template>
  <div>
    <a-form-model :model="form">
      <a-row :gutter="16">
        <a-col :xs="24" :sm="12" :xl="8">
          <a-form-model-item prop="name">
            <a-input v-model="form.name" :allow-clear="true" :max-length="128" placeholder="请输入项目名称"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="8">
          <a-form-model-item>
            <a-button icon="search" type="primary" @click="search">查询</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      row-key="id"
      :row-selection="rowSelection"
      :scroll="{ x: scrollX }"
      style="margin-bottom: 16px;"
    >
      <template slot="name" slot-scope="record">
        <a-tooltip placement="topLeft" :title="record.name">{{ record.name }}</a-tooltip>
      </template>
      <template slot="charger" slot-scope="record">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <div>
              <a-space>
                <span>姓名: </span>
                <span>{{ record.nickname }}</span>
              </a-space>
            </div>
            <div>
              <a-space>
                <span>电话号码: </span>
                <span>{{ record.phone_number }}</span>
              </a-space>
            </div>
            <div>
              <a-space>
                <span>邮箱地址: </span>
                <span>{{ record.email_address }}</span>
              </a-space>
            </div>
          </template>
          <span>{{ record.nickname }}</span>
        </a-tooltip>
      </template>
      <template slot="builder" slot-scope="record">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <div>
              <a-space>
                <span>姓名: </span>
                <span>{{ record.nickname }}</span>
              </a-space>
            </div>
            <div>
              <a-space>
                <span>电话号码: </span>
                <span>{{ record.phone_number }}</span>
              </a-space>
            </div>
            <div>
              <a-space>
                <span>邮箱地址: </span>
                <span>{{ record.email_address }}</span>
              </a-space>
            </div>
          </template>
          <span>{{ record.nickname }}</span>
        </a-tooltip>
      </template>
    </a-table>

    <div style="height: 32px;">
      <span v-if="rowSelection.selectedRowKeys.length" style="line-height: 32px;">{{ `已选中 ${rowSelection.selectedRowKeys.length} 条` }}</span>
      <a-pagination
        v-model="form.page"
        :pageSize="form.pageSize"
        :show-size-changer="true"
        :show-total="t => `共 ${t} 条`"
        :total="total"
        @change="page => { this.form.page = page; this.fetch() }"
        @showSizeChange="(_, pageSize) => { this.form.page = 1; this.form.pageSize = pageSize; this.fetch() }"
        style="float: right;"
      ></a-pagination>
    </div>
  </div>
</template>

<script>
import { getProjectList } from '@/api/project'

export default {
  name: 'ProjectTab',
  data () {
    return {
      form: {
        name: '',
        page: 1,
        pageSize: 10
      },
      columns: [
        { title: '项目名称', width: 200, ellipsis: true, scopedSlots: { customRender: 'name' } },
        { dataIndex: 'charger', title: '项目负责人', width: 200, ellipsis: true, scopedSlots: { customRender: 'charger' } },
        { dataIndex: 'builder', title: '建设负责人', width: 200, ellipsis: true, scopedSlots: { customRender: 'builder' } }
      ],
      dataSource: [],
      total: 0,
      loading: false,
      rowSelection: {
        selectedRowKeys: [],
        onChange: selectedRowKeys => {
          this.rowSelection.selectedRowKeys = selectedRowKeys
        }
      }
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => { sum += column.width })
      return sum
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      const params = {
        page: this.form.page,
        page_size: this.form.pageSize
      }
      if (this.form.name) params.name = this.form.name
      this.loading = true
      getProjectList(params).then((res) => {
        const data = res.data
        this.dataSource = data.data
        this.total = data.total
      }).finally(() => {
        this.loading = false
      })
    },
    search () {
      this.form.page = 1
      this.rowSelection.selectedRowKeys = []
      this.fetch()
    }
  }
}
</script>
