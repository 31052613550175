<template>
  <a-modal
    :confirm-loading="loading"
    title="编辑个人信息"
    :visible="visible"
    :width="720"
    @ok="ok"
    @cancel="reset"
  >
    <a-form-model
      ref="form"
      :label-col="{ span: 4, style: { textAlign: 'left' } }"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="姓名" prop="nickname">
        <a-input v-model="form.nickname" :max-length="128" placeholder="请输入姓名"></a-input>
      </a-form-model-item>
      <a-form-model-item label="电话号码" prop="phone_number">
        <a-input v-model="form.phone_number" :max-length="11" placeholder="请输入电话号码"></a-input>
      </a-form-model-item>
      <a-form-model-item label="邮箱地址" prop="email_address">
        <a-input v-model="form.email_address" :max-length="128" placeholder="请输入邮箱地址"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { updateUserProfile } from '@/api/user'
import { validatePhoneNumber } from '@/utils/validates'

export default {
  name: 'UpdateForm',
  data () {
    return {
      loading: false,
      visible: false,
      form: {
        nickname: '',
        phone_number: '',
        email_address: ''
      },
      rules: {
        nickname: [{ message: '请输入姓名', required: true, trigger: 'blur' }],
        phone_number: [{ validator: validatePhoneNumber, trigger: 'blur' }],
        email_address: [
          { message: '请输入邮箱地址', required: true, trigger: 'blur' },
          { message: '请输入正确的邮箱地址', type: 'email', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    show (detail) {
      this.visible = true
      this.form.nickname = detail.nickname
      this.form.phone_number = detail.phone_number
      this.form.email_address = detail.email_address
    },
    reset () {
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          updateUserProfile(this.form).then(res => {
            this.$message.success(res.message)
            this.$emit('ok')
            this.reset()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
